<template>
  <div v-if="loaded">
    <b-row class="mt-1 w-100">
      <b-col cols="6">
        <b-form-group :label="msg('AirBnb link')" label-for="realEstate-buy-price">
          <b-form-input :disabled="!isAdmin" id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.airbnbLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="msg('Booking link')" label-for="realEstate-buy-price">
          <b-form-input :disabled="!isAdmin" id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.bookingLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="msg('Vrbo link')" label-for="realEstate-buy-price">
          <b-form-input :disabled="!isAdmin" id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.vrboLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="msg('Agoda link')" label-for="realEstate-buy-price">
          <b-form-input :disabled="!isAdmin" id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.agodaLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="msg('Expedia link')" label-for="realEstate-buy-price">
          <b-form-input :disabled="!isAdmin" id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.expediaLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="msg('Treasure link')" label-for="realEstate-buy-price">
          <b-form-input :disabled="!isAdmin" id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.treasureLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-button variant="outline-primary" @click="infoChannel(realEstate)"
        >
          {{ msg('Channel Export') }}
        </b-button>
      </b-col>


    </b-row>
    <b-modal size="sm" :id="channelInfoModal.id"
             hide-footer
             @hide="resetChannelInfoModal">

      <div class="w-100" style="text-align: center">
        <p class="mb-3" style="color:white;font-size:large;font-weight: bold;">Channel Export Request</p>
        <b-form-group>
          <b-row class="features-group">

            <b-col cols="12" class="mb-1">
              <b-button
                  :disabled="channelInfoModal.channelMap!=null&&channelInfoModal.channelMap['Booking']!=null&&channelInfoModal.realEstateTreasure.bookingId!=null"
                  @click="channelExportRealEstateById(channelInfoModal.propertyId,'Booking')"
                  class="w-100"
                  variant="outline-primary">
                <platform-logo v-bind:platform="'Booking'">
                  {{ msg('Booking') }}
                </platform-logo>
              </b-button>
            </b-col>


            <b-col cols="12" class="mb-1">
              <b-button
                  :disabled="channelInfoModal.channelMap!=null&&channelInfoModal.channelMap['Agoda']!=null &&channelInfoModal.realEstateTreasure.agodaId!=null"

                  @click="channelExportRealEstateById(channelInfoModal.propertyId,'Agoda')"
                  class="w-100" variant="outline-primary">
                <platform-logo v-bind:platform="'Agoda'">
                  {{ msg('Agoda') }}
                </platform-logo>
              </b-button>
            </b-col>

            <b-col cols="12" class="mb-1">
              <b-button
                  :disabled="channelInfoModal.channelMap!=null&&channelInfoModal.channelMap['Expedia']!=null &&channelInfoModal.realEstateTreasure.expediaId!=null"

                  @click="channelExportRealEstateById(channelInfoModal.propertyId,'Expedia')"
                  class="w-100"
                  variant="outline-primary">
                <platform-logo v-bind:platform="'Expedia'">
                  {{ msg('Expedia') }}
                </platform-logo>
              </b-button>
            </b-col>

            <b-col cols="12" class="mb-1">
              <b-button
                  :disabled="channelInfoModal.channelMap!=null&&channelInfoModal.channelMap['Vrbo']!=null &&channelInfoModal.realEstateTreasure.vrboId!=null"

                  @click="channelExportRealEstateById(channelInfoModal.propertyId,'Vrbo')"
                  class="w-100"
                  variant="outline-primary">
                <platform-logo v-bind:platform="'Vrbo'">
                </platform-logo>
              </b-button>
            </b-col>

            <b-col cols="12" class="mb-1">
              <b-button
                  :disabled="channelInfoModal.channelMap!=null&&channelInfoModal.channelMap['Airbnb']!=null &&channelInfoModal.realEstateTreasure.airbnbId!=null"

                  @click="channelExportRealEstateById(channelInfoModal.propertyId,'Airbnb')"
                  class="w-100"
                  variant="outline-danger">
                <platform-logo v-bind:platform="'Airbnb'">
                  {{ msg('Airbnb') }}
                </platform-logo>
              </b-button>
            </b-col>

          </b-row>


        </b-form-group>


      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";

export default {
  name: 'realEstateChannels',
  components: {PlatformLogo},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    channelInfoModal: {
      id: 'channel-modal',
      channelMap: null,
      type: null,
      title: '',
      content: '',
      realEstateTreasure: null
    },
  }),
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('user', ['myAdminStatus', 'myRoleId']),

    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    isAdmin() {

      return this.myRoleId === 1;
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  created() {
    this.loaded = true

  },
  methods: {
    ...mapActions('nuki', {_loadNukiList: 'getNukiList', _sendMail: 'sendMail'}),
    ...mapActions('realEstate', ['channelExport']),
    infoChannel(realEstate) {
      this.channelInfoModal.type = null;
      this.channelInfoModal.propertyId = realEstate.id;
      this.channelInfoModal.channelMap = realEstate.channelExport;
      this.channelInfoModal.realEstateTreasure = realEstate.realEstateTreasure;
      this.$root.$emit('bv::show::modal', this.channelInfoModal.id)
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    },
    onValueUpdatedBnb30(args) {
      if (this.realEstate.bnbStyle30)
        this.realEstate.bnbStyle = true
      this.$emit('change', args)
    },
    onValueUpdatedBnb(args) {
      if (!this.realEstate.bnbStyle)
        this.realEstate.bnbStyle30 = false
      this.$emit('change', args)
    },
    resetChannelInfoModal() {
      this.channelInfoModal.type = null;
      this.$root.$emit('bv::hide::modal', this.channelInfoModal.id)
    },
    channelExportRealEstateById(realId, type) {
      let $this = this;
      this.channelExport({id: realId, featureType: type})
          .then(() => {

            $this.refresh()
            $this.resetChannelInfoModal()
          })
    },
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
