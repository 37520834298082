<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div v-if="isMonth" class="card-body pb-0">
            <treasure-calendar-month default-type="month"
                               :currentMonth.sync="currentMonth"
                               v-bind:types="selectedCalendarsIds"
                               @event-selected="onEventSelected"
                               :property-id="propertyId"/>
          </div>
          <div v-else class="card-body pb-0">
            <treasure-calendar-week default-type="month"
                               :currentMonth.sync="currentMonth"
                               v-bind:types="selectedCalendarsIds"
                               @event-selected="onEventSelected"
                               :property-id="propertyId"/>
          </div>
        </div>
      </div>

<!--      &lt;!&ndash; Sidebar Overlay &ndash;&gt;
      <div class="body-content-overlay" :class="{'show': isCalendarOverlaySidebarActive}"
           @click="isCalendarOverlaySidebarActive = false"/>
      <calendar-event-handler v-model="isEventHandlerSidebarActive" v-bind:event-id="event.id"
                              @created="id=>event.id=id" :clear-event-data="clearEventData"/>-->
    </div>
  </div>
</template>

<script>
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import {mapGetters} from 'vuex'
import TreasureCalendarMonth from "@/components/widgets/calendar/treasureCalendarMonth";
import TreasureCalendarWeek from "@/components/widgets/calendar/treasureCalendarWeek";


export default {
  name: "calendarRent",
  components: {
    TreasureCalendarMonth,
    TreasureCalendarWeek,
    // eslint-disable-next-line vue/no-unused-components
    CalendarEventHandler,
  },
  data() {
    return {
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      currentMonth: null,
      selectedCalendarsIds: [],
      event: {id: 0},
    }
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    }, isMonth: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('user', {_myUserId: 'myId'}),
    ...mapGetters('calendar', ['canReadAllCalendar']),
  },
  watch: {
    isCalendarOverlaySidebarActive() {
      this.$root.$emit('bv::hide::popover')
    },
  },
  methods: {
    addNewEvent() {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = null;
      this.event['id'] = 0;
      this.isEventHandlerSidebarActive = true;
    },
    onDaySelected(day) {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = day;
      this.event['id'] = 0;
      this.isEventHandlerSidebarActive = true;
    },
    onEventSelected(eventId) {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = null;
      this.event['id'] = eventId;
      this.isEventHandlerSidebarActive = true;
    },
    clearEventData() {

    }
  }

}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
