<template>
<div class="d-inline-block">
  <div class="d-inline-block" style="vertical-align: text-bottom;">
    <img :src="logos[selectedPlatform]" v-b-tooltip :title="platform" :style="`height: ${size};`"/>
  </div>
  <slot name="default"></slot>
</div>
</template>

<script>
export default {
  name: "PlatformLogo",
  data: () => ({
    logos: {
      airbnb: require("@/assets/images/platforms/airbnb.svg"),
      booking: require("@/assets/images/platforms/bookingcom.svg"),
      agoda: require("@/assets/images/platforms/agoda.svg"),
      expedia: require("@/assets/images/platforms/expedia.svg"),
      vrbo: require("@/assets/images/platforms/vrbo.svg"),
      treasurehome: "https://treasurehome.at/uploads/images/tLogo.png",
      tripadvisor: require("@/assets/images/platforms/tripadvisor.svg"),
      unknown: require("@/assets/images/platforms/unknown.svg"),
    }
  }),
  props: {
    platform: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "21px",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  computed:{
    selectedPlatform(){
      let selectedPlatform = (this.platform || "treasurehome").toLowerCase().replaceAll(" ", "");
      if(this.logos[selectedPlatform] == null){
        selectedPlatform = "unknown";
      }else if(selectedPlatform === "homeaway"){
        selectedPlatform = "vrbo";
      }
      return selectedPlatform;
    }
  }
}
</script>



<style scoped>

</style>
