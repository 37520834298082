<template>
  <div>
    <div v-if="realEstate.marketingType!=null&&realEstate.marketingType.rent!=null &&realEstate.marketingType.rent===true">
      <b-row class="mt-1">
        <b-col cols="2">
          <b-form-group :label="msg('OperatingCost')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.operatingCost"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group :label="msg('Rent per sm')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentPerSqm"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group :label="msg('Rent Netto')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentNetto"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group :label="msg('Rent Brutto')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentBrutto"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>


        <b-col cols="3">
          <b-form-group :label="msg('Rent Total')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentTotal"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group :label="msg('Rent Deposit')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.deposit"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Rent Deposit text')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.depositText"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group :label="msg('Service fees')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number"  v-model="realEstate.realEstatePrices.serviceFee"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

      </b-row>
    </div>
    <div
        v-else-if="realEstate.marketingType!=null&& realEstate.marketingType.purchase!=null && realEstate.marketingType.purchase===true">
      <b-row class="mt-1">

        <b-col cols="4">
          <b-form-group :label="msg('Price Netto')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.priceNetto"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Price Brutto')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.priceBrutto"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Price Total')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.price"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>


        <b-col cols="4">
          <b-form-group :label="msg('Lawyer Fee')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.lawyerFee"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Land Register Fee')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.landRegisterFee"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Ground Tax')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.groundTax"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>


      </b-row>
    </div>
    <div>
      <b-row class="mt-1">



      <b-col cols="4">
        <b-form-group :label="msg('InCourtage')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.inCourtage"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('OutCourtage')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.outCourtage"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Commission')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number"  v-model="realEstate.realEstatePrices.commission"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Commission text')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.commissionText"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="msg('Processing Fee')" label-for="realEstate-buy-price">
            <b-form-input id="realEstate-buy-price" type="number"  v-model="realEstate.realEstatePrices.processFee"
                          @change="onValueUpdated"/>
          </b-form-group>
        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstatePrices',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {

    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
