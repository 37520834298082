<template>
  <div class="mt-2">
    <b-row>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-bath"></i> {{ msg("bath") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.bath.dusche">{{ msg("shower") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.bath.wanne">{{ msg("tub") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.bath.fenster">{{ msg("Window") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.bath.bidet">{{ msg("Bidet") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.bath.pissoir">{{ msg("Pissoir") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-utensils"></i> {{ msg("kitchen") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.kitchen.ebk">{{ msg("ebk") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.kitchen.offen">{{ msg("open") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.kitchen.pantry">{{ msg("pantry") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="3" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ msg("Gastronomy") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.gastronomy.bar">{{ msg("Bar") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.gastronomy.hotelrestaurant">{{ msg("Hotel-Restaurant") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="3" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ msg("Security") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.security.alarmanlage">{{ msg("Alarm system") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.security.kamera">{{ msg("Camera") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.security.polizeiruf">{{ msg("Police call") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-temperature-low"></i> {{ msg("heating System") }}</h5>
        <div>
          <b-form-checkbox inline v-model="furnishEdit.heatingSystem.fern">{{
              msg("district heating")
            }}
          </b-form-checkbox>
          <b-form-checkbox inline v-model="furnishEdit.heatingSystem.etage">{{ msg("etage heating") }}</b-form-checkbox>
          <b-form-checkbox inline v-model="furnishEdit.heatingSystem.zentral">{{
              msg("central heating")
            }}
          </b-form-checkbox>
          <b-form-checkbox inline v-model="furnishEdit.heatingSystem.ofen">{{ msg("oven") }}</b-form-checkbox>
          <b-form-checkbox inline v-model="furnishEdit.heatingSystem.fussboden">{{
              msg("underfloor")
            }}
          </b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-sort-numeric-up-alt"></i> {{ msg("elevator") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.elevator.personen">{{ msg("people elevator") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.elevator.lasten">{{ msg("freight elevator") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-shapes"></i> {{ msg("roofShape") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.roofShape.flachdach">{{ msg("flat roof") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.roofShape.krueppelwalmdach">{{
              msg("half-hipped roof")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.roofShape.mansarddach">{{ msg("mansard roof") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.roofShape.pultdach">{{ msg("pent roof") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.roofShape.satteldach">{{ msg("gable roof") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.roofShape.walmdach">{{ msg("hip roof") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.roofShape.pyramidendach">{{ msg("pyramid roof") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-hammer"></i> {{ msg("construction") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.construction.massiv">{{ msg("massive") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.construction.fertigteile">{{ msg("finished parts") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.construction.holz">{{ msg("wood") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-building"></i> {{ msg("Energy type") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.energyType.niedrigenergie"> {{ msg("low energy") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.passivhaus"> {{ msg("passive house") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.neubaustandard"> {{
              msg("new building std.")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.minergiebauweise"> {{
              msg("minergie const.")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.minergiezertifiziert"> {{
              msg("Minergie certified")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.kfw40"> {{ msg("kfw40") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.kfw55"> {{ msg("kfw55") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.kfw60"> {{ msg("kfw60") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.energyType.kfw70"> {{ msg("kfw70") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ msg("AlignBalconyTerrace") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.sued">{{ msg("south") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.nord">{{ msg("north") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.ost">{{ msg("east") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.west">{{ msg("west") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.nordost">{{
              msg("nord east")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.nordwest">{{
              msg("north west")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.suedost">{{
              msg("south east")
            }}
          </b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.alignmentbalconyterrace.suedwest">{{
              msg("south west")
            }}
          </b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fab fa-delicious"></i> {{ msg("Boden") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.floor.parquet">{{ msg("parquet") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.fertigparkett">{{ msg("finished parquet") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.fliesen">{{ msg("tiles") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.stein">{{ msg("stone") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.teppich">{{ msg("carpet") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.laminat">{{ msg("laminate") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.dielen">{{ msg("plank floor") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.kunststoff">{{ msg("plastic") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.estrich">{{ msg("screed") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.doppelboden">{{ msg("raised floor") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.linoleum">{{ msg("linoleum") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.marmor">{{ msg("marble") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.terrakotta">{{ msg("terracotta") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.floor.granit">{{ msg("granite") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-fire"></i> {{ msg("firing") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.beacon.oel">{{ msg("oil") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.gas">{{ msg("gas") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.elektro">{{ msg("electro") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.alternativ">{{ msg("alternative") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.solar">{{ msg("solar") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.erdwaerme">{{ msg("geothermal") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.luftwp">{{ msg("air") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.fern">{{ msg("distant heating") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.block">{{ msg("block") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.wasserelektro">{{ msg("water electro") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.pellet">{{ msg("pellet") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.kohle">{{ msg("coal") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.holz">{{ msg("wood") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.beacon.fluessiggas">{{ msg("liquid gas") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="3" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ msg("Expansion stage") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.expansionStage.bausatzhaus">{{ msg("Kit house") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.expansionStage.ausbauhaus">{{ msg("Expansion house") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.expansionStage.schluesselfertigmitkeller">{{ msg("Turnkey with basement") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.expansionStage.schluesselfertigohnebodenplatte">{{ msg("Turnkey without floor slab") }}</b-form-checkbox>
          <b-form-checkbox v-model="furnishEdit.expansionStage.schluesselfertigmitbodenplatte">{{ msg("Turnkey with floor slab") }}</b-form-checkbox>
        </div>
      </b-col>
<!--      <b-col cols="3" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-parking"></i> {{ msg("Parking type") }}</h5>
        <div>
          <b-form-checkbox v-model="furnishEdit.parkingType.underground">{{ msg("underground") }}</b-form-checkbox>
        </div>
      </b-col>-->
      <b-col cols="12" lg="12" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-couch"></i> {{ msg("furnished") }}</h5>
        <div>
          <span class="mr-1">{{ msg("Furnitures") }}: </span>
          <b-form-radio inline v-model="furnishEdit.furnished.moeb" name="furnished-moeb" value="VOLL">
            {{ msg("FULL") }}
          </b-form-radio>
          <b-form-radio inline v-model="furnishEdit.furnished.moeb" name="furnished-moeb" value="TEIL">
            {{ msg("PARTIAL") }}
          </b-form-radio>
          <b-form-radio inline v-model="furnishEdit.furnished.moeb" name="furnished-moeb" :value=null>
            {{ msg("NONE") }}
          </b-form-radio>
        </div>
      </b-col>

      <b-col cols="12" lg="12" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-couch"></i> {{ msg("Ausstatt Kategorie") }}</h5>
        <div>
          <span class="mr-1">{{ msg("Quality") }}: </span>
          <b-form-radio inline v-model="furnishEdit.ausstattKategorie" name="furnished-ausstattKategorie" value="STANDARD">
            {{ msg("STANDARD") }}
          </b-form-radio>
          <b-form-radio inline v-model="furnishEdit.ausstattKategorie" name="furnished-ausstattKategorie" value="GEHOBEN">
            {{ msg("ELEVATED") }}
          </b-form-radio>
          <b-form-radio inline v-model="furnishEdit.ausstattKategorie" name="furnished-ausstattKategorie" value="LUXUS">
            {{ msg("LUXURY") }}
          </b-form-radio>
        </div>
      </b-col>
      <b-col cols="12" lg="12" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-couch"></i> {{ msg("Basement") }}</h5>
        <div>
          <span class="mr-1">{{ msg("Quality") }}: </span>
          <b-form-radio inline v-model="furnishEdit.basement.keller" name="furnished-basement" value="JA">
            {{ msg("Yes") }}
          </b-form-radio>
          <b-form-radio inline v-model="furnishEdit.basement.keller" name="furnished-basement" value="NEIN">
            {{ msg("No") }}
          </b-form-radio>
          <b-form-radio inline v-model="furnishEdit.basement.keller" name="furnished-basement" value="TEIL">
            {{ msg("Partial") }}
          </b-form-radio>
        </div>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.cableSatTv"><i class="fas fa-tv"></i>
              {{ msg("CableSatTv") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.wheelchairAccessible"><i class="fab fa-accessible-icon"></i>
              {{ msg("Wheelchair accessible") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.seniorFriendly"><i class="fas fa-walking"></i>
              {{ msg("Senior friendly") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.storageRoom"><i class="fas fa-warehouse"></i>
              {{ msg("Storage room") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.bikeRoom"><i class="fas fa-bicycle"></i> {{ msg("Bike room") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.washdryroom"><i class="fas fa-soap"></i> {{
                msg("Wash-dryroom")
              }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.conservatory"><i class="fas fa-question"></i>
              {{ msg("Conservatory") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.umtsReception"><i class="fas fa-concierge-bell"></i>
              {{ msg("Umts reception") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.sportsFacilities"><i class="fas fa-table-tennis"></i>
              {{ msg("Sports facilities") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.wgSuitable">
              <i class="fas fa-users"></i>
              {{ msg("Wg suitable") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.roomsChangeable"><i class="fas fa-person-booth"></i>
              {{ msg("Rooms changeable") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.airConditioned"><i class="far fa-snowflake"></i>
              {{ msg("Air conditioned") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.guestWC"><i class="fas fa-restroom"></i> {{ msg("Guest toilet") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.shutters"><i class="fas fa-grip-lines"></i>
              {{ msg("Roller shutters") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.gardenUse"><i class="fa fa-tree"></i> {{ msg("Garden use") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.stack"><i class="fas fa-broom"></i> {{ msg("Stack") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.dvbt"><i class="fas fa-wifi"></i> {{ msg("WIFI") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.barrierFree"><i class="fab fa-accessible-icon"></i> {{
                msg("Barrier free" )
              }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.sauna"><i class="fa-solid fa-fire"></i>
              {{ msg("Sauna") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.swimmingpool"><i class="fa fa-swimming-pool"></i>
              {{ msg("Swimmingpool") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.dvcabling"><i class="fas fa-network-wired"></i> {{ msg("Dv cabling") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.ramp">
              <i class="fas fa-stream"></i> {{ msg("Ramp") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.liftingPlatform">
              <i class="fas fa-arrow-up"></i>
              {{ msg("Lifting platform") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.crane">
              <i class="fas fa-magnet"></i> {{ msg("crane") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.guestTerrace">
              <i class="fas fa-wind"></i>
              {{ msg("Guest terrace") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.teaKitchen">
              <i class="fas fa-glass-whiskey"></i> {{ msg("teaKitchen") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.canteenCafeteria">
              <i class="fas fa-glass-whiskey"></i>
              {{ msg("Canteen cafeteria") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.breweryBinding">
              <i class="fas fa-beer"></i>
              {{ msg("Brewery binding") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.wellnessArea"><i class="fas fa-swimming-pool"></i>
              {{ msg("Wellness area") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.library"><i class="fas fa-book"></i>
              {{ msg("Library") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.attic">
              <i class="fas fa-table"></i>
              {{ msg("Attic") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.cableChannels">
              <i class="fa fa-dropbox"></i>
              {{ msg("Cable channels") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.telephonevacationProperty"><i class="fa fa-phone"></i>
              {{ msg("Telephone Vacation Property") }}
            </b-form-checkbox>
          </b-col>
        </b-row>


      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mergeDeep} from "@/plugins/custom-prototypes";

export default {
  name: 'realEstateFurniture',
  props: ['value'],
  data: () => ({
    intervalId: null,
    furnish: {
      "bath": {
        "tub": false
      },
      "kitchen": {
        "ebk": false
      },
      "floor": {
        "parquet": false
      },
      "heatingSystem": {
        "distant": false,
        "floor": false
      },
      "beacon": {
        "distant": false
      },
      "elevator": {
        "people": false
      },
      "roofShape": {
        "flat": false
      },
      "construction": {
        "massive": false
      },
      "level": {
        "turnkeywithcellar": false
      },
      "energyType": {
        "lowPower": false
      },
      "gastronomy": {
      },
      "security": {
      },
      "expansionStage": {
      },
      "furnished": {
      },
      "basement": {
      },
      "alignmentbalconyterrace": {
        "south": false
      },
    /*  "parkingType": {
        "underground": false
      },*/
      "cableSatTv": false,
      "wheelchairAccessible": false,
      "seniorFriendly": false,
      "storageRoom": false,
      "bikeRoom": false,
      "washdryroom": false,
      "conservatory": false,
      "dvbt": false,
      "umtsReception": false,
      "sportsFacilities": false,
      "wgSuitable": false,
      "roomsChangeable": false,
      "airConditioned": false,
      "guestWC": false,
      "shutters": false,
      "gardenUse": false,
    },
    furnishEdit: null,
  }),
  watch: {
    value: {
      handler() {
        this.checkAndMerge();
        this.$emit("change")
      },
      immediate: true
    },
    furnishEdit: {
      handler() {
        this.$emit('input', this.furnishEdit)
      },
      deep: true,
    }
  },
  methods: {
    checkAndMerge() {
      if (this.furnishEdit != null && (this.furnishEdit === this.value || JSON.stringify(this.value) === JSON.stringify(this.furnishEdit)))
        return;
      let mergedValue = mergeDeep(this.value, this.furnish, false)
      this.furnishEdit = {...mergedValue};
      this.$emit('input', this.furnishEdit)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
