<template>
  <div id="todolist">
    <h1>
      {{ msg('todolist') }}
      <span>Get things done, one item at a time.</span>
    </h1>

    <template v-if="todo.length">
      <transition-group class="mb-3" name="todolist" tag="ul">
        <li v-for="item in todoList" :key="item.id" :class="item.done ? 'done' : ''">
          <div class="label-description">
            <div class="label" style="margin-bottom:3px; font-size: 18px;font-weight: bold">{{ item.label }}</div>
            <div class="label description">{{ item.description }}</div>
            <b-badge style="padding: 5px" class="mt-1 w-100" variant="primary">{{ item.tag }}</b-badge>
          </div>
          <div class="actions">
            <b-button
                size="md"
                class="p-1 btn-picto mr-1"
                @click="markAsDoneOrUndone(item)"
                :aria-label="item.done ? 'Undone' : 'Done'"
                :variant="!item.done ? 'danger' : 'success'"
                :title="item.done ? 'Undone' : 'Done'"
            >
              <i
                  aria-hidden="true"
                  class="fas"
                  :class="{ 'fa-check': item.done, 'fa-check-circle': !item.done }"
              ></i>
            </b-button>
            <b-button
                size="md"
                class="p-1 btn-picto"
                @click="deleteItemFromList(item)"
                aria-label="Delete"
                title="Delete"
            >
              <i aria-hidden="true" class="fas fa-trash"></i>
            </b-button>
          </div>
        </li>
      </transition-group>
      <!--      <b-form-checkbox
                class="mt-3"
                id="todosort"
                v-model="sortByStatus"
                name="todosort"
                @change="clickOnToggle"
            >
              Move done items at the end?
            </b-form-checkbox>-->
    </template>
    <p v-else class="emptylist">{{ msg('yourtodolistisempty') }}</p>

    <b-col>
      <b-form-group class="mb-2" :label="msg('Title')" label-for="titleInput">
        <b-input-group>
          <b-form-input
              id="titleInput"
              v-model="newitem"
              @input="validateForm"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group class="mb-2" :label="msg('Description')" label-for="descriptionInput">
        <b-textarea
            id="descriptionInput"
            v-model="newDescription"
            @input="validateForm"
        />
      </b-form-group>


      <b-form-group :label="msg('Tag')" label-for="realEstate-branch">
        <v-select
            :dir="isRTL ? 'rtl' : 'ltr'"
            :clearable="true"
            v-model="newTag"
            :options="[
        'Online setup',
        'Airbnb',
        'Booking',
        'Pricelabs',
        'Expedia',
        'Agoda',
        'Immoscout24',
        'Möbel',
        'Accessoires',
        'Ausstattung',
        'Online Bestellung',
        'Hostaway',
        'Küchenausstattung (Geschirr, Töpfe/Pfannen und Besteck)',
        'Bad-Essentials',
        'Luxuriöse Produkte',
        'Renovierung',
        'Aufbauen',
        'Reparieren',
        'Internet',
        'Hand/Badetücher',
        'Bettwäsche',
        'Küchen Elektrogeräte',
        'Installationsarbeiten',
        'Vorhänge'
        ]"

            class="w-100"

        />
      </b-form-group>

      <div class="d-flex justify-content-end mt-2">
        <b-button
            :disabled="!isFormValid"
            size="lg"
            variant="outline-primary"
            class="px-1"
            v-b-tooltip
            :title="msg('Save item')"
            @click="addItem"
        >
          {{ msg('Add') }}
        </b-button>
      </div>
    </b-col>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  name: 'TodoList',
  setup() {
    // Import the useAppConfig function to access the skin property
    const {skin} = useAppConfig()

    // Access the current skin value
    const currentSkin = skin.value // Assuming 'skin' is a computed property

    return {
      currentSkin,
    }
  },
  data() {
    return {
      isFormValid: false,
      newitem: '',
      newDescription: '',
      newTag: '',
      sortByStatus: false,
      todo: [],
    };
  },
  watch: {
    currentSkin: {
      handler: function (val, oldVal) {
        console.log('skin changed from ', oldVal, ' to ', val)
      },
      deep: true,
    },
  },
  created() {

    this.todo = this.value.todoList || [];
    console.log('skin: ',this.currentSkin)
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    todoList() {
      if (!this.sortByStatus) {
        return this.todo;
      }

      const doneArray = this.todo.filter((item) => item.done);
      const notDoneArray = this.todo.filter((item) => !item.done);

      return [...notDoneArray, ...doneArray];
    },
  },
  methods: {
    ...mapActions("realEstate", ["handleTodoEvent",]),
    validateForm() {
      // Check if both title and description are filled
      this.isFormValid = this.newitem !== '' && this.newDescription !== '';
    },
    addItem() {
      this.todo.push({
        id: Math.floor(Math.random() * 9999) + 10,
        label: this.newitem,
        description: this.newDescription,
        tag: this.newTag,
        done: false
      });

      let item = {};
      item.label = this.newitem;
      item.description = this.newDescription;
      item.tag = this.newTag;
      this.handleEventTodoList('add', item);

      this.newitem = '';
      this.newDescription = '';
      this.newTag = '';

    },
    markAsDoneOrUndone(item) {
      item.done = !item.done;

      this.handleEventTodoList('update', item);

    },
    deleteItemFromList(item) {
      this.handleEventTodoList('delete', item);

      const index = this.todo.indexOf(item);
      this.todo.splice(index, 1);


    },

    handleEventTodoList(event, todo) {
      this.handleTodoEvent({id: this.value.id, event: event, item: todo}).then(() => {

      })
    },
  },

};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@keyframes strikeitem {
  to {
    width: calc(100% + 1rem);
  }
}

#todolist {
  margin: 4rem auto;
  padding: 2rem 3rem 3rem;
  max-width: 80%;
  box-shadow: -20px -20px 0px 0px rgba(100, 100, 100, .1);
}

#todolist h1 {
  /*text-align:center;*/
  font-weight: normal;
  font-size: 2.6rem;
  letter-spacing: 0.05em;
  border-bottom: 1px solid rgb(0, 0, 0);
}

#todolist h1 span {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.7rem;
  margin-left: 3px;
  margin-top: 0.2rem;
}

#todolist .emptylist {
  margin-top: 2.6rem;
  text-align: center;
  letter-spacing: .05em;
  font-style: italic;
  opacity: 0.8;

}

#todolist ul {
  margin-top: 2.6rem;
  list-style: none;
}

#todolist .todolist-move {
  transition: transform 1s;
}

.dark-layout #todolist li {
  display: flex;
  margin: 0 -3rem 4px;
  padding: 1.1rem 3rem;
  justify-content: space-between;
  align-items: center;
  background: rgba(64, 64, 64, 0.28);;
}
#todolist li {
  display: flex;
  margin: 0 -3rem 4px;
  padding: 1.1rem 3rem;
  justify-content: space-between;
  align-items: center;
  background: rgb(216, 216, 216);;
}

#todolist .actions {
  flex-shrink: 0;
  padding-left: 0.7em;
}

#todolist .label {
  position: relative;
  transition: opacity .2s linear;
}

#todolist .done .label {
  opacity: .3;
}

#todolist .done .label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -.5rem;
  display: block;
  width: 0%;
  height: 1px;
  background: #FFF;
  animation: strikeitem .3s ease-out 0s forwards;
}

#todolist .btn-picto {
  border: none;
  background: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: #FFF;
}


</style>
