<template>
  <div v-if="loaded">
    <b-row class="mt-1 w-100">

      <b-col cols="3">
        <b-form-group label="External API" label-for="realEstate-buy-price">
          <v-select
              id="realEstate-buy-price"
              v-model="realEstate.externalApiType"
              :options="['HOSTAWAY', 'RENTAL']"
              @change="onValueUpdated"
              :clearable="false"
              placeholder="Select an option"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <b-row class="mt-1 w-100">
      <b-col cols="3">
        <b-form-group label="Hostaway ID" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.hostawayId"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Father Hostaway ID" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.fatherHostawayId"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Hostaway reservations')" label-for="realEstate-sell-price">
          <b-button variant="primary" :disabled="true" @click="fetchHostawayReservations">
            <b-spinner small v-if="hostawayLoading" class="mr-2"></b-spinner>
            Fetch Hostaway reservations
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group :label="msg('Crm contracts out of Hostaway')" label-for="realEstate-sell-price">
          <b-badge variant="primary">
            {{ realEstate.totalReservations != null ? realEstate.totalReservations : "Not availble at this moment" }}
          </b-badge>

        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-1 w-100">
      <b-col cols="3">
        <b-form-group label="Rental united ID" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.rentalId"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
      </b-col>
      <b-col cols="3">
        <b-form-group :label="msg('Rental reservations')" label-for="realEstate-sell-price">
          <b-button variant="primary" :disabled="true" @click="fetchHostawayReservations">
            <b-spinner small v-if="hostawayLoading" class="mr-2"></b-spinner>
            Fetch Rental reservations
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group :label="msg('Crm contracts out of Rental united')" label-for="realEstate-sell-price">
          <b-badge variant="primary">Nt available at this moment</b-badge>

        </b-form-group>
      </b-col>
    </b-row>


    <b-row class="mt-5 w-100">

      <b-col cols="3">
        <b-form-group :description="msg('BnB Style rent (default ab 1 Nacht)') ">
          <b-form-checkbox
              v-model="realEstate.bnbStyle"
              name="check-button" switch
              @change="onValueUpdatedBnb">
          </b-form-checkbox>
        </b-form-group>

      </b-col>

      <b-col cols="3">
        <b-form-group :description="msg('30 Nächte Minimum') ">
          <b-form-checkbox
              v-model="realEstate.bnbStyle30"
              name="check-button" switch
              @change="onValueUpdatedBnb30">
          </b-form-checkbox>
        </b-form-group>

      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('CheckIn time')" label-for="messageTemplate-company">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="timeOptions"
              v-model="realEstate.checkInTime"
              class="w-100"
              label="label"
              :reduce="(branch) => branch.value"
          ></v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('CheckOut time')" label-for="messageTemplate-company">

          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="timeOptions"
              v-model="realEstate.checkOutTime"
              class="w-100"
              label="label"
              :reduce="(branch) => branch.value"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="CheckIn Details" label-for="realEstate-buy-price">
          <b-textarea id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.keyCode"
                      @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Wifi Username" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.wifiUsername"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Wifi Password" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.wifiPassword"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>


      <b-col cols="3">
        <b-form-group :label="msg('One payment') +' in %'" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.oneTimePayment"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group :label="msg('Discount 3-6 months') +' in %'" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.discount3"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Discount 6-12 months') +' in %'" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.discount6"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Discount 12+ months') +' in %'" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.discount12"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group :label="msg('Review Online')" label-for="realEstate-buy-price">
          <b-form-textarea v-model="realEstate.realEstateTreasure.review" @change="()=>onValueUpdated"
                           size="sm"></b-form-textarea>

        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'realEstateTreasureRent',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    hostawayLoading: false,
    timeOptions:
        [
          {label: "00:00", value: "00:00"},
          {label: "00:30", value: "00:30"},
          {label: "01:00", value: "01:00"},
          {label: "01:30", value: "01:30"},
          {label: "02:00", value: "02:00"},
          {label: "02:30", value: "02:30"},
          {label: "03:00", value: "03:00"},
          {label: "03:30", value: "03:30"},
          {label: "04:00", value: "04:00"},
          {label: "04:30", value: "04:30"},
          {label: "05:00", value: "05:00"},
          {label: "05:30", value: "05:30"},
          {label: "06:00", value: "06:00"},
          {label: "06:30", value: "06:30"},
          {label: "07:00", value: "07:00"},
          {label: "07:30", value: "07:30"},
          {label: "08:00", value: "08:00"},
          {label: "08:30", value: "08:30"},
          {label: "09:00", value: "09:00"},
          {label: "09:30", value: "09:30"},
          {label: "10:00", value: "10:00"},
          {label: "10:30", value: "10:30"},
          {label: "11:00", value: "11:00"},
          {label: "11:30", value: "11:30"},
          {label: "12:00", value: "12:00"},
          {label: "12:30", value: "12:30"},
          {label: "13:00", value: "13:00"},
          {label: "13:30", value: "13:30"},
          {label: "14:00", value: "14:00"},
          {label: "14:30", value: "14:30"},
          {label: "15:00", value: "15:00"},
          {label: "15:30", value: "15:30"},
          {label: "16:00", value: "16:00"},
          {label: "16:30", value: "16:30"},
          {label: "17:00", value: "17:00"},
          {label: "17:30", value: "17:30"},
          {label: "18:00", value: "18:00"},
          {label: "18:30", value: "18:30"},
          {label: "19:00", value: "19:00"},
          {label: "19:30", value: "19:30"},
          {label: "20:00", value: "20:00"},
          {label: "20:30", value: "20:30"},
          {label: "21:00", value: "21:00"},
          {label: "21:30", value: "21:30"},
          {label: "22:00", value: "22:00"},
          {label: "22:30", value: "22:30"},
          {label: "23:00", value: "23:00"},
          {label: "23:30", value: "23:30"}
        ],


  }),
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  created() {
    this.loaded = true

  },
  methods: {
    ...mapActions('nuki', {_loadNukiList: 'getNukiList', _sendMail: 'sendMail'}),
    ...mapActions('realEstate', ['fetchHostawayContracts']),


    fetchHostawayReservations() {
      this.hostawayLoading = true; // Start loading
      this.fetchHostawayContracts(this.realEstate.id)
          .then(() => {
            // Handle success if needed
          })
          .catch(error => {
            // Handle error if needed
            console.error("An error occurred:", error);
          })
          .finally(() => {
            this.hostawayLoading = false; // Stop loading on either success or error
          });
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    },
    onValueUpdatedBnb30(args) {
      if (this.realEstate.bnbStyle30)
        this.realEstate.bnbStyle = true
      this.$emit('change', args)
    },
    onValueUpdatedBnb(args) {
      if (!this.realEstate.bnbStyle)
        this.realEstate.bnbStyle30 = false
      this.$emit('change', args)
    },

  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
